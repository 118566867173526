.widget-side{
  display: none;
  width:100%;
  //  border: 1px solid #b3b3b3;
  text-align:center;
  margin-bottom:5px;
  padding:10px;
  cursor: pointer;
  text-transform: capitalize;
  i{
    font-size: 20px;
    display:block;
    margin-bottom:8px;
  }

}

.tp-widget{
  position:relative;
  vertical-align:bottom;
  margin: 10px;
  .widget-inner{
    margin:20px 0;
  }

  .widget-admin{
    display: none;
  }

  .widget-loading{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    text-align:center;
    z-index: 100;
    background: rgba(255,255,255,.6);
  }
}

.widget-sortable{

  //  &.hover {
  //    //border: 1px dotted #b3b3b3;
  //    .widget-front {
  //      .widget-helpers {
  //
  //        display: block;
  //
  //      }
  //    }
  //  }

  .widget-front{
    position:relative;

    .widget-helpers{
      display:none;
      position:absolute;
      right:0;
      left:0;
      top: -32px;
      z-index: 1000;
      background: rgba(255,255,255,.6);
      padding:5px 10px;

      &.hover{
        display:block;
      }

      .btn{
        margin:0 10px;
      }
    }




  }
}

.widget-avail{
  .widget-side{
    display: block;
  }

  .widget-front{
    display: none;
  }
}

#widget-wrapper{
  padding:0;
  min-height: 300px;
  width:100%;
  border: 2px dashed #999;
  margin-bottom:100px;
}

.widgets-nav{
  color: #fff;
  font-size:16px;
  padding:10px 15px;
  float:right;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #999;

  &:hover{
    color: #fff;
    background: #999;
    text-decoration:none;
  }
}

#widget-settings-form{
  //  margin:30px;
}

.widgets-nav{
  display:none;
}

.is-sticky .widgets-nav-more{
  display:block;
}


.is-sticky .inner-widgets{
  display:none;
}

.is-sticky.sticky-open {
  .inner-widgets {
    display: block;
  }

  .widgets-nav-more {
    display:none;
  }

  .widgets-nav-less {
    display:block;
  }
}

.tp-widget.ui-draggable-dragging{

  .widget-side{
    display:block;
    width:100px;
  }
  .widget-inner{
    display:none;
  }


}
